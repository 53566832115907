<template>
  <div class="search-box-main">
    <div class="search-box-container">
      <b-input
        class="search-input"
        icon-right="magnify"
        placeholder="Search for comments"
        @blur="handleBlur"
        v-model="review"
      ></b-input>
      <!-- <input
        type="search"
        v-model="review"
        @blur="handleBlur" /> -->
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { debounce } from '../../../util/util'

/** This component renders the Search input field found on the **Comments** page */
export default {
  name: 'searchReview',
  data() {
    return {
      review: ''
    }
  },
  computed: {
    ...mapState('filters', ['searchKeyWord']),
    ...mapState('user', ['userDetails'])
  },
  watch: {
    review() {
      this.debounceInput()
    },
    searchKeyWord() {
      this.review = this.searchKeyWord
    }
  },
  mounted() {
    this.updateSearchKeyWord(this.review)
  },
  methods: {
    ...mapActions('filters', ['updateSearchKeyWord']),
    ...mapActions('analytics', ['updateEvent']),
    ...mapActions('trendingbuzz', ['updateSkip']),
    /** This method introduces a delay between the user input and the *updateSearchKeyword()* method, so that the search functionality is not called too frequently, or before the user has a chance to complete the word they're typing
     * @public
     */
    debounceInput: debounce(function (e) {
      this.updateSearchKeyWord(this.review)
      this.updateSkip(0)
    }, 500),

    /** I'm unsure as to what this method does. */
    handleBlur() {
      this.updateEvent({
        userId: this.userDetails.userId,
        event: 'click',
        label: 'filter_search',
        pageUrl: window.location.href
      })
    }
  }
}
</script>

<style lang="scss" >
.search-box-main {
  .search-box-container {
    .search-input {
      .input::-moz-placeholder {
        color: var(--input-search-text-color);
      }
      .input::-webkit-input-placeholder {
        color: var(--input-search-text-color);
      }
      .input:-moz-placeholder {
        color: var(--input-search-text-color);
      }
      .input:-ms-input-placeholder {
        color: var(--input-search-text-color);
      }
      .input {
        box-shadow: none;
        padding: 5px 10px 5px 15px;
        outline: none;
        height: 36px;
        border-radius: 4px;
        // background: var(--local-dropdown-background-color);
        background-color: var(--input-field-background);
        border: none;
        border: var(--secondary-border-color);
        font-size: 14px;
        width: 100%;
        font-family: Quicksand;
        color: var(--input-search-text-color);
      }
    }
  }
}
.highlight {
  background-color: #ffec81;
  color: #323232;
}
</style>
