<template>
  <div v-if="review_link && review_link.length" class="open-link-container">
    <a
      @mouseenter="showPopUp(true)"
      @mouseleave="showPopUp(false)"
      class="open-link"
      :href="review_link"
      target="_blank">
      <img :src="require('../../assets/extract.svg')" />
    </a>
    <p v-if="show">Open review in new tab</p>
  </div>
</template>

<script>
/** This component contains the code required to open the selected review at its source */
export default {
  name: 'OpenTab',
  // props: ['review_link'],
  props: {
    /** This prop contains the source link for the review */
    review_link: String
  },
  data () {
    return {
      show: false
    }
  },
  methods: {
    showPopUp (val) {
      this.show = val
    }
  }
}
</script>

<style lang="scss" scoped>
.open-link-container {
  position: relative;
  .open-link {
    img {
      height: 16px;
    }
  }
  p {
    position: absolute;
    width: 180px;
    right: 0%;
    transform: translateX(10%);
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 4px;
    top: -120%;
    background: white;
    padding: 2px;
    text-align: center;
    margin: 0px;
    font-size: 14px;
    font-weight: 500;
    font-family: Quicksand;
  }
}
</style>
