<template>
  <div v-if="open">
    <div class="local-loader-container" v-if="loading">
        <div class="inava-loader"></div>
      </div>
    <table class="custom-vue-table">
      <tr v-for="(review, index) of reviewData" :key="index">
        <td>
          <review-vue
            :key="review.description"
            :index="index"
            :proscons="review.like_dislike"
            :description="review.description"
            :review="review" />
        </td>
      </tr>
    </table>
    <div v-if="totalCount > 0" class="pagination-main">
      <div class="pagination-block">
        <div class="pagination-flex">
          <p>Showing</p>
          <dropdown-wrap-vue @onClickOutside="onClickOutside">
            <div class="pagination-filter">
              <div @click="openCountSelection()" class="style-dynamic-count">
                <p>{{ Math.min(limit, reviewData.length) }}</p>
                <svg xmlns="http://www.w3.org/2000/svg" width="7.072" height="7.072" viewBox="0 0 7.072 7.072" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="#a8aab7" d="M3.889 6.718a.5.5 0 01-.707 0L.353 3.889a.5.5 0 11.708-.707l2.474 2.475L6.01 3.182a.5.5 0 11.707.707z"/></svg>
              </div>
              <ul v-if="openPag" class="pagination-filter-inside">
                <li
                  @click="changeFormat(ind)"
                  v-for="(ind, index) in [10, 50, 100, 200]"
                  :key="index">
                  {{ ind }}
                </li>
              </ul>
            </div>
          </dropdown-wrap-vue>
          out of
          <div class="total-count">{{ totalCount }}</div>
        </div>
        <b-pagination
          :total="totalCount"
          v-model="page"
          range-before="1"
          range-after="1"
          order="is-centered"
          size="is-small"
          :simple="false"
          :rounded="false"
          :per-page="limit"
          icon-prev="chevron-left"
          icon-next="chevron-right">
        </b-pagination>
      </div>
    </div>
    <NoDataContainerVue v-if="totalCount == 0" :data="reviewData" type="trendingBuzz" />
  </div>
  <NoDataContainerVue v-else :data="reviewData" type="trendingBuzz" />
</template>

<script>
import { mapActions, mapState } from 'vuex'
import moment from 'moment'
import ReviewVue from './Review.vue'
import NoDataContainerVue from '../Common/NoDataContainer/NoDataContainer.vue'
// import TagsVue from './Tags.vue'
import DropdownWrapVue from '../Common/DropdownWrap.vue'

export default {
  name: 'CustomTable',
  components: {
    // TagsVue,
    ReviewVue,
    NoDataContainerVue,
    DropdownWrapVue
  },
  data () {
    return {
      // open: true,
      openPag: false,
      page: 1
    }
  },

  computed: {
    ...mapState('filters', [
      'selectedSourceList',
      'selectedCompanies',
      'selectedCompaniesVoe',
      'selectedIndustries',
      'selectedFeatures',
      'selectedRating',
      'userType',
      'searchKeyWord',
      'selectedProducts',
      'ActiveVocTimePeriod',
      'ActiveVoeTimePeriod',
      'subCategories',
      'selectedCategories',
      'selectedEmployeeStatuses',
      'previousPeriodRange',
      'selectedBusinessFunc',
      'selectedSeniorityLevel'

    ]),
    ...mapState('common', ['currentModule']),
    ...mapState('trendingbuzz', [
      'reviewData',
      'limit',
      'skip',
      'totalCount',
      'sort',
      'loading'
    ]),
    ...mapState('sunriseSunset', [
      'customSortedAttributes',
      'selectedAttribute',
      'featuredTopicsData',
      'polarSentimentAttributesOnly',
      'open'
    ]),
    ...mapState('user', ['userDetails']),
    watchable () {
      return (
        JSON.stringify(this.selectedSourceList) +
        JSON.stringify(this.selectedIndustries) +
        JSON.stringify(this.selectedCompanies) +
        JSON.stringify(this.selectedProducts) +
        JSON.stringify(this.ActiveVocTimePeriod) +
        JSON.stringify(this.selectedCompaniesVoe) +
        JSON.stringify(this.ActiveVoeTimePeriod) +
        JSON.stringify(this.selectedEmployeeStatuses) +
        JSON.stringify(this.selectedBusinessFunc) +
        JSON.stringify(this.selectedSeniorityLevel)
      )
    }
  },

  watch: {
    watchable () {
      if (this.watchable.length) {
        this.getAllData()
      }
    },
    page () {
      this.updateSkip((this.page - 1) * this.limit)
    },
    selectedAttribute () {
      this.page = 1
      this.getAllData()
    },
    selectedFeatures () {
      this.getAttributeData()
      this.getAllData()
    },
    selectedRating () {
      this.getAllData()
    },
    limit () {
      this.getAllData()
    },
    skip () {
      this.page = this.skip / this.limit + 1
      this.getAllData()
    },
    userType () {
      if (this.userType.length) {
        this.updateOpenStatus(true)
      }
      this.getAllData()
    },
    searchKeyWord () {
      this.getAllData()
    },
    sort () {
      this.getAllData()
    }
  },
  beforeDestroy () {
    this.updateSelectedRating([])
    this.updateSelectedFeatures([])
    this.updateUserType([])
  },
  methods: {
    ...mapActions('trendingbuzz', [
      'getWordCloudData',
      'updateLimit',
      'updateSkip',
      'updateSelectedCount'
    ]),
    ...mapActions('filters', [
      'updateSelectedRating',
      'updateSelectedFeatures',
      'updateUserType'
    ]),
    ...mapActions('sunriseSunset', [
      'getFeaturedTopicsData',
      'updatecustomSortedAttributes',
      'getCustomSortedAttributes',
      'updateOpenStatus'
    ]),
    onClickOutside (event) {
      this.openPag = false
    },

    changeFormat (count) {
      this.openPag = !this.openPag
      this.defaultCount = count
      this.updateLimit(count)
    },
    getDate (date) {
      return moment(date).format('MM/DD/YYYY')
    },
    openCountSelection () {
      this.openPag = !this.openPag
    },
    async getAllData () {
      if (
        this.currentModule === 'voc' &&
        this.selectedSourceList.length &&
        this.selectedProducts.length &&
        this.selectedCompanies.length &&
        this.selectedIndustries.length &&
        this.selectedFeatures.length &&
        this.selectedRating.length &&
        this.userType.length &&
        this.ActiveVocTimePeriod != null &&
        this.subCategories.length
      ) {
        const data = {
          module: this.currentModule,
          source: this.selectedSourceList.map((item) => item.id),
          products: this.selectedProducts.map((item) => item),
          companySize: this.selectedCompanies.map((item) => item.id),
          industry: this.selectedIndustries.map((item) => item.id),
          features: this.selectedFeatures.map((item) => {
            return { id: item.id, isStandard: item.is_standard }
          }),
          rating: this.selectedRating.map((item) => item.id),
          limit: this.limit,
          startTime: this.ActiveVocTimePeriod.range[0],
          endTime: this.ActiveVocTimePeriod.range[1],
          skip: this.skip,
          userType: this.userType.map((item) => item.id),
          searchKeyWord: this.searchKeyWord,
          categoryId: this.subCategories, // .map((data) => data.id),
          sort: this.sort,
          subscriptionId: this.selectedCategories.subscriptionId,
          polarSentimentAttributesOnly: this.polarSentimentAttributesOnly,
          // requestingModuleId: this.userDetails.modules.filter(m => m.module_name === 'voc')[0].module_id
          requestingModuleId: this.userDetails.modules.find(
            (m) => m.module_name === 'voc'
          ).module_id
        }
        // if (this.selectedAttribute && this.selectedAttribute.word) {
        //   data.attribute = this.selectedAttribute.word
        // }
        if (this.selectedAttribute && this.selectedAttribute.length) {
          data.attributes = this.selectedAttribute
        }
        this.getWordCloudData(data)
        this.getAttributeData()
        // if (!this.customSortedAttributes.length) {
        //   this.getAttributeData()
        // }
      } else if (
        this.currentModule === 'voe' &&
        this.userType.length &&
        this.selectedFeatures.length &&
        this.selectedRating.length &&
        this.selectedCompaniesVoe.length &&
        this.selectedEmployeeStatuses.length &&
        this.selectedBusinessFunc.length &&
        this.selectedSeniorityLevel.length &&
        this.ActiveVoeTimePeriod != null
      ) {
        const data = {
          module: this.currentModule,
          companies: this.selectedCompaniesVoe.map((item) => item.id),
          employeeStatus: this.selectedEmployeeStatuses.map((item) => item.id),
          businessFunc: this.selectedBusinessFunc.map(
            (item) => `'${item.name}'`
          ),
          seniorityLevel: this.selectedSeniorityLevel.map(
            (item) => `'${item.name}'`
          ),
          startTime: this.ActiveVoeTimePeriod.range[0],
          endTime: this.ActiveVoeTimePeriod.range[1],
          features: this.selectedFeatures.map((item) => {
            return { id: item.id, isStandard: item.is_standard }
          }),
          userType: this.userType.map((item) => item.id),
          limit: this.limit,
          rating: this.selectedRating.map((item) => item.id),
          searchKeyWord: this.searchKeyWord,
          skip: this.skip,
          sort: this.sort,
          polarSentimentAttributesOnly: this.polarSentimentAttributesOnly
        }
        if (this.selectedAttribute && this.selectedAttribute.length) {
          data.attributes = this.selectedAttribute
        }
        this.getWordCloudData(data)
        this.getAttributeData()
      } else {
        this.updatecustomSortedAttributes([])
      }
      this.checkDataSelection()
    },
    checkDataSelection () {
      if (
        this.currentModule === 'voc' &&
        !(
          this.selectedSourceList.length &&
          this.selectedProducts.length &&
          this.selectedCompanies.length &&
          this.selectedIndustries.length &&
          this.selectedFeatures.length &&
          this.selectedRating.length &&
          this.userType.length
        )
      ) {
        this.updateOpenStatus(false)
        // this.updateReviewData([])
      } else if (
        this.currentModule === 'voe' &&
        !(
          this.selectedCompaniesVoe.length &&
          this.selectedFeatures.length &&
          this.selectedEmployeeStatuses.length &&
          this.selectedBusinessFunc.length &&
          this.selectedSeniorityLevel.length &&
          this.selectedRating.length &&
          this.userType.length
        )
      ) {
        this.updateOpenStatus(false)
      } else {
        this.updateOpenStatus(true)
      }
    },
    getMomentData (date) {
      return moment(date).format("MMM'YY")
    },
    getAttributeData () {
      if (
        this.currentModule === 'voc' &&
        this.selectedSourceList.length &&
        this.selectedProducts.length &&
        this.selectedIndustries.length &&
        this.selectedCategories != null &&
        this.subCategories.length &&
        this.selectedCompanies.length &&
        this.ActiveVocTimePeriod &&
        this.selectedFeatures.length
      ) {
        const data = {
          module: this.currentModule,
          source: this.selectedSourceList.map((item) => item.id),
          products: this.selectedProducts,
          startTime: this.ActiveVocTimePeriod.range[0],
          endTime: this.ActiveVocTimePeriod.range[1],
          industries: this.selectedIndustries.map((item) => item.id),
          companySize: this.selectedCompanies.map((item) => item.id),
          categoryId: this.subCategories,
          subscriptionId: this.selectedCategories.subscriptionId,
          allTopics: this.selectedFeatures.map((item) => {
            return { id: item.id, is_standard: item.is_standard }
          }),
          polarSentimentAttributesOnly: this.polarSentimentAttributesOnly
        }
        this.getCustomSortedAttributes(data)
      } else if (
        this.currentModule === 'voe' &&
        this.userType.length &&
        this.selectedFeatures.length &&
        this.selectedEmployeeStatuses.length &&
        this.selectedBusinessFunc.length &&
        this.selectedSeniorityLevel.length &&
        this.selectedRating.length &&
        this.selectedCompaniesVoe.length &&
        this.ActiveVoeTimePeriod != null
      ) {
        const data = {
          module: this.currentModule,
          companies: this.selectedCompaniesVoe.map((item) => item.id),
          employeeStatus: this.selectedEmployeeStatuses.map((item) => item.id),
          businessFunc: this.selectedBusinessFunc.map(
            (item) => `'${item.name}'`
          ),
          seniorityLevel: this.selectedSeniorityLevel.map(
            (item) => `'${item.name}'`
          ),
          startTime: this.ActiveVoeTimePeriod.range[0],
          endTime: this.ActiveVoeTimePeriod.range[1],
          allTopics: this.selectedFeatures.map((item) => {
            return { id: item.id, is_standard: item.is_standard }
          }),
          polarSentimentAttributesOnly: this.polarSentimentAttributesOnly
        }
        this.getCustomSortedAttributes(data)
      } else {
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.custom-vue-table {
  min-width: 100%;
}

.local-loader-container {
    position: absolute;
    display: flex;
    top: 0;
    left: 225px;
    width: 100%;
    height: 100%;
    z-index: 9;
    width: calc(100% - 225px);;
    flex: 1;
    min-height: 290px;
    height: 100%;
    justify-content: center ;
    align-items: center;
  }

.pagination-main {
  padding-bottom: 20px;
  .pagination-block {
    border: none;
    border-top: var(--c-sat-driver-table-border);
    border-top-width: 1px;
    border-top-style: solid;
    font-size: 14px;
    background: var(--primary);
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    .pagination-flex {
      display: flex;
      align-items: center;
      color: var(--description);
      .pagination-filter {
        position: relative;
        .pagination-filter-inside {
          position: absolute;
          background: var(--dropdown-background-comment);
          bottom: -40%;
          left: 0%;
          max-height: 100px;
          overflow-y: auto;
          list-style-type: none;
          color: var(--description-comments);
          margin: 0;
          cursor: pointer;
          li {
            padding: 0 8px;
          }
          li:first-child {
            padding-top: 5px;
          }
          li:last-child {
            padding-bottom: 5px;
          }
          li:hover {
            background-color: var(--dropdown-background-comment-hover);
          }
          border-radius: 4px;
          box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        }
        .style-dynamic-count {
          padding: 0px 5px;
          height: 28px;
          cursor: pointer;
          border: var(--tertiary-border-color);
          border-radius: 5px;
          margin: 0px 5px;
          display: flex;
          align-items: center;
          svg {
            margin-left: 5px;
          }
          p {
            color: var(--description-comments);
          }
        }
      }
      .total-count {
        padding: 5px;
        border-radius: 5px;
        margin: 0px;
        color: var(--description-comments);
      }
    }
  }
}
</style>
