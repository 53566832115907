<template>
  <div class="tags-block">
    <ul>
      <!-- <li>
        <custom-star-rating-vue :avgRating="review.c_sat_rating">
        </custom-star-rating-vue>
      </li> -->
      <li class="label-text">Mentioned In</li>
      <li v-if="currentModule === 'voc'">
        <b-tag
          rounded
          close-icon-type="is-success"
          type=""
          aria-close-label="Close tag"
          class="round-chips round-tag-1"
          >{{ review.product_name }}</b-tag
        >
      </li>
      <!-- {{
        tagList
      }} -->
      <li v-for="(tag, index) in tagList" :key="tag.name">
        <b-tag
          rounded
          close-icon-type="is-success"
          type=""
          aria-close-label="Close tag"
          class="round-chips"
          :class="`round-tag-${index + 2}`"
        >
          {{ tag.name }}</b-tag
        >
      </li>
    </ul>
    <!-- <open-tab-vue :review_link="review.review_link" /> -->
  </div>
</template>

<script>
import moment from 'moment'
import OpenTabVue from './OpenTab.vue'
import CustomStarRatingVue from '../CustomStarRating.vue'
import { mapState } from 'vuex'

/** This component displays the meta info about a review. */
export default {
  name: 'Tags',
  components: {
    OpenTabVue,
    CustomStarRatingVue
  },
  props: ['review'],
  computed: {
    ...mapState('common', ['currentModule']),
    tagList() {
      if (this.currentModule === 'voc') {
        return [
          { name: this.review.source_name },
          { name: this.review.industry_name },
          { name: this.review.company_size }
        ]
      } else {
        return [
          { name: this.review.company_name },
          { name: this.review.employee_status },
          { name: this.review.business_function },
          { name: this.review.seniority_level }
        ].filter(obj => obj.name !== 'Confidential')
      }
    }
  },
  methods: {
    /**
     * This method formats an entered date into the *MM/DD/YYYY* format
     * @param data {Number}
     * @public
     */
    getDate(date) {
      return moment(date).format('MM/DD/YYYY')
    }
  }
}
</script>
<style lang="scss" scoped>
.round-chips {
  // background: #813535 0% 0% no-repeat padding-box;
  // border: 0.75px solid #c7c7c7;
  opacity: 1;
  text-align: left;
  font: normal normal 12px/16px Quicksand;
  letter-spacing: 0px;
  color: #888888;
  opacity: 1;
  margin-left: 1%;
}

.round-tag-1 {
  background: var(--comments-tag-background-color-1);
  color: var(--comments-tag-color-1);
}
.round-tag-2 {
  background: var(--comments-tag-background-color-2);
  color: var(--comments-tag-color-2);
}
.round-tag-3 {
  background: var(--comments-tag-background-color-3);
  color: var(--comments-tag-color-3);
}
.round-tag-4 {
  background: var(--comments-tag-background-color-4);
  color: var(--comments-tag-color-4);
}

.label-text {
  text-align: left;
  font: normal normal 12px/15px Quicksand;
  font-weight: 600;
  letter-spacing: 0px;
  color: #888888;
  opacity: 1;
  margin-top: 4px;
  @media (min-width: 1450px) and (max-width: 1700px) {
    font: normal normal 13px/17px Quicksand;
    font-weight: 600;
  }
  @media (min-width: 1700px) {
    font: normal normal medium 16px/20px Quicksand;
    font-weight: 600;
  }
}
.tags-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px;

  .start-rating {
    display: flex;
    align-items: center;
    padding: 2px 5px;
    border-radius: 5px;
    justify-content: center;
  }
  ul {
    list-style-type: none;
    margin: 0px;
    padding: 0px;
    display: inline-flex;
    flex-wrap: wrap;
    font-size: 12px;
    li {
      display: flex;
      align-items: center;
      color: var(--description-light);
      padding: 2px 7px;
      border-radius: 4px;
      font-family: Quicksand;
      margin: 5px 5px 0px 0px;
      img {
        height: 12px;
      }
    }
    .tag-back {
      background-color: var(--tags-background-color);
    }
  }
}
</style>
