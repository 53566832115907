<template>
  <div class="ratings">
    <div class="star" v-for="num in avgRating" :key="num">
      <img src="../assets/full_star.svg" />
    </div>
    <div class="star" v-for="num in -(avgRating - 5)" :key="num + 5">
      <img src="../assets/empty_star.svg" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    avgRating: Number
  }
}
</script>

<style lang="scss" scoped>
.ratings {
  display: flex;
  height: 100%;
  align-items: center;
  .star {
    display: flex;
    padding-right: 2px;
  }
}
</style>
