<template>
  <div class="data-source-container">
    <dropdown-wrap-vue @onClickOutside="onClickOutside">
      <ul>
        <li class="all-list">
          <label>
            <input
              type="checkbox"
              id="selectAll"
              v-model="isSelected"
              @click="selectAllData(!isSelected)" />
            <div class="line-block">
              <p>{{ 'Select All' }}</p>
            </div>
          </label>
        </li>
      </ul>
      <ul v-for="(object, index) of list" :key="index">
        <li class="all-list">
          <label>
            <input
              type="checkbox"
              :id="object"
              :value="object"
              v-model="localSelected" />
            <div class="line-block">
              <p>{{ object.name }}</p>

              <div v-if="custom" class="align-text">
                <img :src="require(`../../assets/${object.name}.svg`)" :alt="object.name">
                <p v-if="statistics[object.name]">{{!isNaN(statistics[object.name])?  Number(statistics[object.name].toFixed(1))+'%':''}}</p>
                <p v-else>0%</p>
              </div>
            </div>
          </label>
        </li>
      </ul>
    </dropdown-wrap-vue>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { analyticsEvents } from '../../constant/data'
import DropdownWrapVue from '../Common/DropdownWrap.vue'

/**
 * This component acts a multi-selector dropdown. It does not have search/filtering capabilites. It is used in the sidepanel/global filters and on the **Comments** page as well.
 */
export default {
  name: 'MultiSelectorNew',
  components: {
    DropdownWrapVue
  },
  // props: ['list', 'onChange', 'selected', 'label', 'placeholder', 'custom', 'statistics', 'className'],
  props: {
    /** This prop contains the list that is used to populate the dropdown */
    list: Array,
    /** This prop defines the behaviour when a new item is selected */
    onChange: Function,
    /** This prop contains an array containing the information of selected objects */
    selected: Array,
    /** This prop contains/defines the text that appears as a label over the dropdown */
    label: String,
    /** This prop contains the text when no item has been selected */
    placeholder: String,
    /** This prop indicates that there are some custom elements that are inserted beside each *(Those under the v-for loop)* option in the dropdown. Currently used to insert the Reaction symbols and percentages on the Comments page  */
    custom: Boolean,
    /** This prop contains additional (statistical) info about each option in the dropdown. Currently being used on the Comments page  */
    statistics: Object,
    /** This prop contains a class name for the dropdown */
    className: String
  },
  data () {
    return {
      open: false,
      localSelected: [],
      isSelected: false
    }
  },
  computed: {
    ...mapState('user', ['userDetails'])
  },
  watch: {
    selected () {
      if (
        this.selected.length === this.list.length &&
        this.selected.length > 0
      ) {
        this.isSelected = true
      } else {
        this.isSelected = false
      }
      this.localSelected = this.selected
    },
    localSelected () {
      if (this.list.length) {
        this.$emit('onChange', this.localSelected)
      }
    }
  },
  mounted () {
    this.localSelected = this.selected
    this.list.length === this.selected.length
      ? (this.isSelected = true)
      : (this.isSelected = false)
  },
  methods: {
    ...mapActions('analytics', ['updateEvent']),
    /**
     * This method closes the dropdown if a click is detected anywhere outside the boundary of the dropdown menu
     * @public
     */
    onClickOutside (event) {
      this.open = false
    },
    /**
     * This method opens the dropdown menu, and calls an *updateAnalytics()* method if the *updateAnalytics* prop is present
     * @public
     */
    openTab () {
      this.open = !this.open
      if (this.open) {
        this.updateEvent({
          userId: this.userDetails.userId,
          event: 'click',
          label:
            analyticsEvents[this.label.length ? this.label : this.placeholder],
          pageUrl: window.location.href
        })
      }
    },
    /**
     * This method selects all options in the dropdown
     * @public
     */
    selectAllData (isSelected) {
      if (!isSelected) {
        this.localSelected = []
      } else {
        this.localSelected = this.list
      }
    }
  }
}
</script>

<style scoped lang="scss">
.all-list {
  width: 100%;
  margin-top: 0.2em;
  label {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;

    .line-block {
      width: 90%;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      p {
        width: 100%;
        text-align: left;
        font: normal normal 12px/15px Quicksand;
        font-weight: 600;
        letter-spacing: 0px;
        color: var(--filter-checkbox-label);
        opacity: 1;
        padding: 0.2em;
        @media (min-width: 1450px) and (max-width: 1700px) {
          font: normal normal 13px/17px Quicksand;
          font-weight: 600;
        }
        @media (min-width: 1700px) {
          font: normal normal 16px/20px Quicksand;
          font-weight: 600;
        }
      }
      div {
        width: 35%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        img {
          width: 30%;
        }

        p {
          width: 70%;
        }
      }
    }
  }
}
</style>
