<template>
  <div
    class="table-container-block"
    :style="{ borderLeftColor: `${colorSelected(proscons)}` }"
  >
    <div class="custom-vue-div">
      <div class="column is-12 left-custom-vue">
        <div class="date-view">{{ dateString(review.published_at) }}</div>
        <div class="comment-view">
          <template v-if="newDescription.length <= 300">
            <span v-html="newDescription"></span>
          </template>
          <template v-else>
            <template v-if="!readMore">
              <span v-html="newDescription.substring(0, 200) + '...'"></span>
            </template>
            <template v-if="readMore">
              <span v-html="newDescription"></span>
            </template>
            <span class="read-button" @click="showMore()" v-if="!readMore"
              >Show more</span
            >
            <span class="read-button" @click="showLess()" v-if="readMore"
              >Show less</span
            >
            <!-- <button
              @click="showMore(script.id)"
              v-if="!readMore[script.id]"
              class="btn btn-primary"
            >
              Show more
            </button> -->
          </template>
        </div>
      </div>
      <div class="right-custom-vue">
        <open-tab-vue :review_link="review.review_link" />
      </div>
    </div>
    <div class="columns custom-vue-div-1">
      <div class="column is-12 left-custom-vue-1">
        <tags-vue :review="review" />
      </div>
      <div class="right-custom-vue-1"
      @mouseenter="showPopUp(true)"
      @mouseleave="showPopUp(false)">
        <img
          v-if="proscons == 'POSITIVE'"
          :src="require(`../../assets/reaction-emoji/Like.svg`)"
        />
        <img
          v-if="proscons == 'NEGATIVE'"
          :src="require(`../../assets/reaction-emoji/Dislike.svg`)"
        />
        <img
          v-if="proscons == 'NEUTRAL'"
          :src="require(`../../assets/reaction-emoji/Neutral.svg`)"
        />
        <img
          v-if="proscons == 'MIXED'"
          :src="require(`../../assets/reaction-emoji/Mixed.svg`)"
        />
        <p v-if="showSmileyTitle"> {{ getSmileyTitle(proscons) }} </p>
      </div>
    </div>
  </div>
  <!-- <NoDataContainerVue v-else :data="reviewData" type="trendingBuzz" /> -->
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'
import TagsVue from './Tags.vue'
import OpenTabVue from './OpenTab.vue'

export default {
  name: 'CustomTable',
  components: {
    TagsVue,
    OpenTabVue
  },
  //   props: ['review'],
  props: {
    proscons: String,
    /** This prop contains the text content of the review */
    description: String,
    review: Object,
    // index: Number
    showReadMoreButton: Boolean,
    id: String
  },
  data() {
    return {
      readMore: false,
      viewLess: false,
      newDescription: this.description,
      showSmileyTitle: false
    }
  },
  computed: {
    ...mapState('filters', ['searchKeyWord']),
    ...mapState('sunriseSunset', ['selectedAttribute'])
  },
  watch: {
    searchKeyWord() {
      this.highlightSearchKeyword()
    }
  },
  mounted() {
    // if (this.$refs.calcHeight.clientHeight > 63) {
    //   this.readMore = true
    // }
    this.highlightSearchKeyword()
    // this.dateString(this.review.published_at)
  },
  methods: {
    colorSelected(proscons) {
      if (proscons === 'POSITIVE') {
        return '#535EEB80'
      } else if (proscons === 'NEGATIVE') {
        return '#F5777780'
      } else if (proscons === 'NEUTRAL') {
        return '#EDCD3580'
      } else {
        return '#88888880'
      }
    },

    showMore() {
      this.readMore = true
    },
    showLess() {
      this.readMore = false
    },
    dateString(date) {
      var date = new Date(date)
      return moment(date).format('dddd, MMMM Do YYYY')
      // return moment(date).format('YYYY-MM-DD HH:mm Z')
      // console.log(moment(date).format('YYYY-MM-DD HH:mm'))
    },
    /** This method expands the review if some of it has been truncated
     * @public
     */
    openFullMessage() {
      this.readMore = !this.readMore
      this.viewLess = !this.viewLess
    },
    /** This method checks the review to see if it contains a certain keyword, and highlights all locations of the keyword
     * @public
     */
    highlightSearchKeyword() {
      // These characters have special meaning when used in a regular expression. This regex is used to escape those characters if they appear the review text.

      const formattedKeyword = this.searchKeyWord.replace(
        /[-[\]{}()*+?.,\\^$|#\s]/g,
        '\\$&'
      )
      const substring = new RegExp(formattedKeyword, 'gi')
      let description = this.description
      description = description.replace(substring, (match) => {
        if (this.searchKeyWord !== '') {
          return `<span class="highlight" id="search">${match}</span>`
        }
        return match
      })
      this.newDescription = description
      if (this.selectedAttribute && this.selectedAttribute.length) {
        let words = []
        this.selectedAttribute.forEach((sattr) => {
          const kwArr = this.selectedAttribute.map((a) => [...a.keywords])
          words.push(...new Set(kwArr.flat()))
        })
        words = [...new Set(words)]
        words.forEach((word) => {
          if (/\band\b/gim.test(word)) {
            // console.log(word)
            const phraseWords = word.split(' ')
            for (let i = 0; i < phraseWords.length; i++) {
              const pw = phraseWords[i]
              if (pw === 'and' && i > 0) {
                const left = phraseWords[i - 1]
                const right = phraseWords[i + 1]
                const stringl = new RegExp(`\\b${left.trim()}\\b`, 'gmi')
                const stringr = new RegExp(`\\b${right.trim()}\\b`, 'gmi')
                if (stringl.test(description) && stringr.test(description)) {
                  description = description.replace(stringl, (match) => {
                    if (match !== '' && match !== ' ') {
                      return `<span class="highlight" id="search">${match}</span>`
                    }
                    return match
                  })
                  description = description.replace(stringr, (match) => {
                    if (match !== '' && match !== ' ') {
                      return `<span class="highlight" id="search">${match}</span>`
                    }
                    return match
                  })
                  // console.log(stringl, stringr, description)
                }
              }
            }
            // console.log(word, description, '\n')
          } else {
            const string = new RegExp(`\\b${word}\\b`, 'gmi')
            // description = this.description
            description = description.replace(string, (match) => {
              if (word !== '' && word !== ' ') {
                return `<span class="highlight" id="search">${match}</span>`
              }
              return match
            })
          }
        })
        this.newDescription = description
      }
    },

    /** This method toggle popup title for smileys
     * @public
     */
    showPopUp (isShow) {
      this.showSmileyTitle = isShow
    },

    /** This method returns smileys title
     * @public
     */
    getSmileyTitle (smileyTitle) {
      switch (smileyTitle) {
        case 'POSITIVE': return 'Like'
        case 'NEGATIVE': return 'Dislike'
        case 'NEUTRAL': return 'Neutral'
        case 'MIXED': return 'Mixed'
        default: return ''
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.left-custom-vue {
  width: 90%;
  float: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.date-view {
  text-align: left;
  display: flex;
  align-items: center;
  font: normal normal 11px/12px Quicksand;
  font-weight: 600;
  letter-spacing: 0px;
  color: var(--comment-date-text);
  text-transform: uppercase;
  opacity: 1;
  margin-top: 0.5em;
  // margin-top: 4px;
  margin-left: 0.7rem;
  @media (min-width: 1450px) and (max-width: 1700px) {
    font: normal normal 12px/15px Quicksand;
    font-weight: 600;
  }
  @media (min-width: 1700px) {
    font: normal normal 14px/18px Quicksand;
    font-weight: 600;
  }
}

.comment-view {
  text-align: left;
  font: normal normal bold 12px/16px Quicksand;
  letter-spacing: 0px;
  color: var(--comment-text);
  opacity: 1;
  margin-top: 0.5em;
  margin-left: 0.7rem;
  @media (min-width: 1450px) and (max-width: 1700px) {
    font: normal normal 15px/19px Quicksand;
    font-weight: 600;
  }
  @media (min-width: 1700px) {
    font: normal normal bold 18px/23px Quicksand;
  }
}
.custom-vue-div {
  width: 100%;
  //   background-color: aqua;
  height: auto;
  border: 1px;
  border-color: blue;
  border-bottom: 0.5px solid var(--comments-page-divider);
}
.custom-vue-div-1 {
  width: 100%;
  //   background-color: rgb(0, 255, 34);
  height: 40%;
  border: 1px;
  border-color: blue;
}
.table-container-block {
  width: 100%;
  background-color: var(--comment-block-background-color);
  height: auto;
  display: flex;
  flex-direction: column;
  border-left: 5px solid;
  // border-bottom: 8px solid var(--tags-background-color);
  margin-bottom: 10px;
}

.left-custom-vue-1 {
  // width: 100%;
  // //   background-color: red;
  // float: left;
  // height: 100%;
  // display: flex;
  // flex-direction: row;
  // flex-wrap: wrap;

  width: 93%;
  float: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.tags-view {
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  //   background-color: red;
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.7rem;
}
.right-custom-vue {
  width: 5%;
  float: right;
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin: 0.5em;
}
.right-custom-vue-1 {
  width: 5%;
  float: right;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-content: center;
  margin-right: 0.5em;
  margin-top: 1em;
  height: 23px;
  position: relative;

  p {
    position: absolute;
    right: 0%;
    transform: translateX(10%);
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 4px;
    top: -120%;
    background: white;
    padding: 2px 8px;
    text-align: center;
    margin: 0px;
    font-size: 14px;
    font-weight: 500;
    font-family: Quicksand;
  }

  @media (min-width: 1450px) and (max-width: 1700px) {
    height: 27px;
    margin-top: 0.7em;
  }
  @media (min-width: 1700px) {
    height: 34px;
    margin-top: 0.6em;
  }
}
.round-chips {
  // background: #813535 0% 0% no-repeat padding-box;
  // border: 0.75px solid #c7c7c7;
  opacity: 1;
  text-align: left;
  font: normal normal 11px/16px Quicksand;
  font-weight: 400;
  letter-spacing: 0px;
  color: var(--filter-checkbox-label);
  opacity: 1;
  margin-left: 1%;
  @media (min-width: 1450px) and (max-width: 1700px) {
    font: normal normal 12.5px/15px Quicksand;
    font-weight: 400;
  }
  @media (min-width: 1700px) {
    font: normal normal medium 14px/18px Quicksand;
    font-weight: 400;
  }
}

.round-tag-1 {
  background: #fe6d731a 0% 0% no-repeat padding-box;
  color: #f57777;
}
.round-tag-2 {
  background: #feca7d33 0% 0% no-repeat padding-box;
  color: #555b6e;
}
.round-tag-3 {
  background: #227c9d1a 0% 0% no-repeat padding-box;
  color: #227c9d;
}
.round-tag-4 {
  background: #17c3b21a 0% 0% no-repeat padding-box;
  color: #17c3b2;
}
.read-button {
  color: #f57777;
  cursor: pointer;
  margin-left: 0.2rem;
}
</style>
