<template>
  <div>
    <div class="columns is-mobile fiter-cooments">
      <div class="column filter-text">Filter Comments by</div>
    </div>
    <div class="columns is-mobile checkbox-list-reaction">
      <div class="column reaction-list">
        <div class="label-text">Reaction</div>
        <div>
          <div class="list-contaner">
            <div class="list-field">
              <MultiSelectorVue
                label=""
                placeholder="Reaction"
                :list="reactionList"
                :selected="userType"
                :custom="true"
                :statistics="computedRatingData"
                @onChange="onChangeReactions"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="columns is-mobile checkbox-list-feature">
      <div class="column date-list">
        <div class="label-text">Feature</div>
        <div class="list-contaner">
          <MultiSelectorVue
            label=""
            placeholder="Feature"
            className="auto-width"
            :list="features"
            :selected="selectedFeatures"
            @onChange="onChangeFeatures"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import MultiSelectorVue from '../Dropdowns/MultiSelectorNew.vue'

/**
 * This component renders the first row of filters on the **Comments** page
 */
export default {
  name: 'VerticalLocalFilters',
  components: {
    MultiSelectorVue
  },
  data() {
    return {
      emptyVoCStatistics: {
        Like: 0,
        Dislike: 0,
        Neutral: 0
      },
      emptyVoEStatistics: {
        Like: 0,
        Dislike: 0,
        Mixed: 0,
        Neutral: 0
      }
    }
  },
  computed: {
    ...mapState('filters', [
      'features',
      'selectedFeatures',
      'selectedCategories',
      'selectedRating',
      'subCategories',
      'companyList',
      'userType',
      'ratingList',
      'reactionList',
      'selectedProducts',
      'selectedIndustries',
      'selectedSourceList',
      'selectedCompanies',
      'selectedCompaniesVoe',
      'selectedFeatures',
      'selectedCountries',
      'selectedEmployeeStatuses',
      'selectedBusinessFunc',
      'selectedSeniorityLevel'
    ]),
    ...mapState('trendingbuzz', [
      'totalCount',
      'ratingData',
      'sort',
      'sortOptions'
    ]),
    ...mapState('common', ['currentModule']),
    ...mapState('user', ['userDetails']),
    // eslint-disable-next-line vue/return-in-computed-property
    computedRatingData() {
      if (
        this.currentModule === 'voc' &&
        !(
          this.selectedProducts.length &&
          this.selectedSourceList.length &&
          this.selectedIndustries.length &&
          this.selectedCompanies.length &&
          this.userType.length &&
          this.selectedFeatures.length &&
          this.selectedRating.length &&
          this.selectedCountries.length
        )
      ) {
        return this.emptyVoCStatistics
      } else if (
        this.currentModule === 'voe' &&
        !(
          this.selectedCompaniesVoe.length &&
          this.selectedEmployeeStatuses.length &&
          this.selectedBusinessFunc.length &&
          this.selectedSeniorityLevel.length &&
          this.selectedFeatures.length &&
          this.userType.length
        )
      ) {
        return this.emptyVoEStatistics
      }
      return this.ratingData
    }
  },
  watch: {
    subCategories() {
      if (this.subCategories.length) {
        this.getLocalData()
      }
    },
    companyList() {
      if (this.companyList.length) {
        this.getLocalData()
      }
    },
    userType() {
      if (!this.userType.length) {
        this.resetRatingData()
      }
    }
  },
  created() {
    this.initializeSortOptions(this.currentModule)
  },
  mounted() {
    if (this.subCategories.length || this.companyList.length) {
      this.getLocalData()
    }
  },
  methods: {
    ...mapActions('filters', [
      'getAllLocalFiltersData',
      'getAllVoeLocalFiltersData',
      'updateSelectedFeatures',
      'updateSelectedRating',
      'updateUserType',
      'updateSearchKeyWord'
    ]),
    ...mapActions('analytics', ['updateEvent']),
    ...mapActions('trendingbuzz', [
      'resetRatingData',
      'updateSort',
      'initializeSortOptions'
    ]),
    /**
     * This method filters the comments according to user reactions
     * @param data {Array} - Takes in an array of objects. An object can look like the following:
     * <br>
     * *{ id: 'POSITIVE', name: 'Like' }*
     * @public
     */
    onChangeReactions(data) {
      this.updateUserType(data)
    },
    /**
     * This method filters the comments according to topics/features
     * @param data {Array} - Takes in an array of objects. An object can look like the following: <br>
     * *{ id: 3, is_standard: true, name: 'Customization', parent_id: null }*
     * @public
     */
    onChangeFeatures(data) {
      this.updateSelectedFeatures(data)
    },
    /**
     * This method filters the comments according to the rating of the review. Currently not in use
     */
    onChangeRating(data) {
      this.updateSelectedRating(data)
    },
    /** This method gets all the reviews that fall under the domain of the local filters
     * @public
     */
    getLocalData() {
      if (this.currentModule === 'voc') {
        this.getAllLocalFiltersData({
          categoryId: this.subCategories,
          subscriptionId: this.selectedCategories.subscriptionId,
          module: this.currentModule
        })
      } else if (this.currentModule === 'voe') {
        this.getAllVoeLocalFiltersData({ module: this.currentModule })
      }
    },
    /** This method resets all local filters to their default states. It results in all reviews under the domain of the global filters being displayed
     * @public
     */
    onReset() {
      this.updateUserType(this.reactionList)
      this.updateSelectedFeatures(this.features)
      this.updateSelectedRating(this.ratingList)
      this.updateSearchKeyWord('')
      this.updateSort({
        type: 'published_at',
        orderBy: null
      })
    },
    /**
     * This method is used to update the sorting for the drivers. It is only used in the table views
     * @param data {Object} - eg. *{ type: 'product_name', orderBy: 'asc' }*
     * @public
     */
    onChangeSelection(data) {
      this.updateSort(data)
    }
  }
}
</script>

<style lang="scss" scoped>
.label-text {
  text-align: left;
  font: normal normal bold 12px/15px Quicksand;
  letter-spacing: 0px;
  color: var(--filter-heading-text);
  opacity: 1;
  @media (min-width: 1450px) and (max-width: 1700px) {
    font: normal normal 13px/17px Quicksand;
    font-weight: 600;
  }
  @media (min-width: 1700px) {
    font: normal normal 16px/20px Quicksand;
    font-weight: 600;
  }
}

// span.control-label {
//   text-align: left;
//   font: normal normal medium 14px/19px Quicksand;
//   letter-spacing: 0px;
//   color: #888888;
//   opacity: 1;
// }

.fiter-cooments {
  border-bottom: 0.5px solid var(--comments-page-divider);
  // border-bottom: 0.5px solid var(--filter-border-color);
  opacity: 1;
  height: 50px;
}
.filter-text {
  text-align: left;
  font: normal normal 13px/16px Quicksand;
  font-weight: 600;
  letter-spacing: 0px;
  color: var(--filter-heading-text);
  opacity: 1;
  @media (min-width: 1450px) and (max-width: 1700px) {
    font: normal normal 15px/19px Quicksand;
    font-weight: 600;
  }
  @media (min-width: 1700px) {
    font: normal normal 18px/23px Quicksand;
    font-weight: 600;
  }
}
.checkbox-list-reaction {
  border-bottom: 0.5px solid var(--comments-page-divider);
  // border-bottom: 1px solid #c7c7c7;
}
.list-contaner {
  margin-top: 0.8em;
  width: 100%;
}
// .column {
//   padding: 0px;
// }
.inside-list {
  display: flex;
  // background-color: #888888;
  width: 100%;
  justify-content: space-between;
}
.icon-percentage {
  width: 30%;
  // background-color: #da2f2f;
  display: flex;
  flex-direction: row;
  font: normal normal 14px/19px Quicksand;

  img {
    width: 30%;
  }
  span {
    margin-left: 10%;
  }
}
.list-field {
  width: 100%;

  // input {
  //   background: #888888 0% 0% no-repeat padding-box;
  //   opacity: 1;
  //   height: 14px;
  //   width: 14px;
  // }
  // label {
  //   text-align: left;
  //   font: normal normal 14px/19px Quicksand;
  //   letter-spacing: 0px;
  //   color: #888888;
  //   opacity: 1;
  //   margin-left: 5%;
  // }
}
.list-field-1 {
  width: 100%;

  input {
    background: #888888 0% 0% no-repeat padding-box;
    opacity: 1;
    height: 14px;
    width: 14px;
  }
  label {
    text-align: left;
    font: normal normal 14px/19px Quicksand;
    letter-spacing: 0px;
    color: #888888;
    opacity: 1;
    margin-left: 5%;
  }
}
// .year-fliter {
//   display: flex;
//   align-items: center;
//   padding: 0px 0px 20px 0px;
//   justify-content: space-between;
//   .review-count {
//     color: var(--tertiary-text-color);
//     display: flex;
//     height: 36px;
//     font-size: 12px;
//     align-items: center;
//     font-family: Roboto;
//   }
//   .inside-flex {
//     display: flex;
//     flex-direction: column;
//     padding-bottom: 0px;
//     flex-wrap: wrap;
//     align-items: center;
//     .reset-container {
//       display: flex;
//       padding-right: 10px;
//     }
//     .reset-button-one {
//       display: flex;
//       align-items: center;
//       padding-right: 10px;
//       color: #2780eb;
//     }
//     .selectors {
//       display: flex;
//       align-items: center;
//       .right-padding {
//         padding-right: 10px;
//       }
//       .data-source-container {
//         margin-right: 10px;
//       }
//     }
//   }
//   .disabledtime {
//     color: lightgrey;
//   }
// }
</style>
