<template>
  <dropdown-wrap-vue @onClickOutside="closeAttributePopUp">
    <div class="columns is-mobile featured-container" @click="expandRight()">
      <div class="column is-9 featured-text">
        <!-- {{ status }} -->
        <span>
          <p>Featured Topics</p>
        </span>
      </div>

      <div class="column is-3 featured-button">
        <img
          src="../../assets/collapse-left-arrow.svg"
        />
      </div>
    </div>
    <div class="featured-chips">
      <b-field
        v-for="attribute in customSortedAttributes"
        :key="attribute.word"
      >
        <b-tag
          rounded
          close-type="is-white"
          close-icon-type="is-success"
          aria-close-label="Close tag"
          :class="
            selectedAttribute &&
            selectedAttribute.filter((a) => a.word === attribute.word).length
              ? 'active'
              : 'round-chips'
          "
          @click="updateSortedAttribute(attribute)"
          >{{ attribute.word }} {{ attribute.new_c }}</b-tag
        >
      </b-field>
    </div>
    <!-- <div class="featured-chips">
      <b-field>
        <b-tag
          rounded
          closable
          close-type="is-white"
          close-icon-type="is-success"
          aria-close-label="Close tag"
          class="round-chips"
          >Rounded tag label Round
        </b-tag>
      </b-field>
    </div> -->
  </dropdown-wrap-vue>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import DropdownWrapVue from '../Common/DropdownWrap.vue'
export default {
  components: {
    DropdownWrapVue
  },
  data() {
    return {
      open: true,
      showAll: false,
      cssVars: {
        '--attrleft': '0px',
        '--attrright': '0px',
        '--attrwidth': '900px'
      },
      isAttrsOverflown: false
    }
  },
  props: ['status'],
  computed: {
    ...mapState('sunriseSunset', [
      'customSortedAttributes',
      'selectedAttribute',
      'polarSentimentAttributesOnly'
    ])
  },
  watch: {
    customSortedAttributes() {
      const availablewords = this.customSortedAttributes.map((e) => e.word)
      const invalidSelections = this.selectedAttribute.filter(
        (s) => !availablewords.includes(s.word)
      )
      if (invalidSelections.length && !this.polarSentimentAttributesOnly) {
        this.updateSelectedAttribute(
          this.selectedAttribute.filter((s) => availablewords.includes(s.word))
        )
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.checkAttrsOverflown()
    }, 10)
  },
  updated() {
    this.checkAttrsOverflown()
  },
  methods: {
    ...mapActions('sunriseSunset', [
      'updatecustomSortedAttributes',
      'updateSelectedAttribute'
    ]),
    expandRight() {
      this.$emit('sidebarExpand')
    },
    updateSortedAttribute(d) {
      const that = this
      let attributeArray = []
      if (this.selectedAttribute && this.selectedAttribute.length > 0) {
        const arr = this.selectedAttribute.filter((a) => a.word === d.word)
        if (arr.length) {
          attributeArray = this.selectedAttribute.filter(
            (a) => a.word !== d.word
          )
        } else {
          this.selectedAttribute.push(d)
          attributeArray = [...this.selectedAttribute]
        }
        this.updateSelectedAttribute(attributeArray)
        // this.updatecustomSortedAttributes(this.customSortedAttributes.sort(function (x, y) {
        //   return that.selectedAttribute.filter(a => a.word === x.word).length ? -1 : that.selectedAttribute.filter(a => a.word === y.word).length === d.word ? 1 : 0
        // }))
      } else {
        const arr = this.selectedAttribute.filter((a) => a.word === d.word)
        if (!arr.length) {
          this.selectedAttribute.push(d)
          attributeArray = [...this.selectedAttribute]
          this.updateSelectedAttribute(attributeArray)
          // this.updatecustomSortedAttributes(this.customSortedAttributes.sort(function (x, y) {
          //   return that.selectedAttribute.filter(a => a.word === x.word).length ? -1 : that.selectedAttribute.filter(a => a.word === y.word).length === d.word ? 1 : 0
          // }))
        }
      }
    },
    toggleShowAll() {
      const attrs = document.getElementsByClassName('attrs')[0]
      const ft = document.getElementsByClassName('feature-title')[0]
      const left =
        attrs.getBoundingClientRect().left - ft.getBoundingClientRect().left
      const va = document.getElementsByClassName('view-all')[0]
      const right = attrs
        ? va.getBoundingClientRect().right -
          attrs.getBoundingClientRect().right +
          10
        : 0
      const width = attrs.getBoundingClientRect().width
      this.cssVars = {
        '--attrleft': left + 'px',
        '--attrright': right + 'px',
        '--attrwidth': width + 'px'
      }
      this.showAll = !this.showAll
    },
    closeAttributePopUp() {
      this.showAll = false
    },
    checkAttrsOverflown() {
      const element = document.getElementsByClassName('attrs')[0]
      this.isAttrsOverflown = element
        ? element.scrollHeight > element.clientHeight ||
          element.scrollWidth > element.clientWidth
        : false
    }
  }
}
</script>

<style lang="scss" scoped>
.featured-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  border-bottom: 2px solid #c7c7c7;
  opacity: 1;
  height: auto;
}
.round-chips {
  background: var(--round-chip-background);
  border: 0.75px solid #c7c7c7;
  opacity: 1;
  text-align: left;
  font: normal normal 11 px/16px Quicksand;
  font-weight: 600;
  letter-spacing: 0px;
  color: var(--filter-checkbox-label);
  opacity: 1;
  margin-left: 5%;
  margin-bottom: 0.2em;
  cursor: pointer;
  @media (min-width: 1450px) and (max-width: 1700px) {
    font: normal normal 13px/16px Quicksand;
  }
  @media (min-width: 1700px) {
    font: normal normal 15px/18px Quicksand;
  }
}
.active {
  text-align: left;
  font: normal normal 11px/14px Quicksand;
  opacity: 1;
  margin-left: 5%;
  background-color: var(--round-chip-active-background);
  color: var(--round-chip-active-color);
  cursor: pointer;
  @media (min-width: 1450px) and (max-width: 1700px) {
    font: normal normal 13px/16px Quicksand;
  }
  @media (min-width: 1700px) {
    font: normal 600 14px/18px Quicksand;
  }
}
.featured-text {
  text-align: left;
  font: normal normal 14px/16px Quicksand;
  font-weight: 600;
  letter-spacing: 0px;
  color: var(--filter-heading-text);
  opacity: 1;
  width: 85%;
  span {
    display: flex;
    flex-direction: row;
    p {
      margin-left: 4%;
    }
  }
  &:hover {
    cursor: pointer;
  }
}

.featured-button {
  text-align: left;
  font: normal normal 11px/16px Quicksand;
  letter-spacing: 0px;
  color: #f57777;
  opacity: 1;
  width: 15%;
  cursor: pointer;
}

.featured-chips {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.8rem;
  padding-bottom: 1rem;
  span {
    margin-right: 8px;
  }
}
</style>
