<template>
  <div class="year-fliter">
    <div class="review-count">
      {{ !noData ? totalCount : 0 }}
      <template v-if="(totalCount > 1 && totalCount !== 0) || noData">Comments</template>
      <template v-else>Comment</template>
    </div>
    <div class="inside-flex">
      <div class="search-review">
        <div class="search-container">
          <SearchReviewVue />
        </div>
      </div>
      <div class="golbal-sort">
        <global-sort-vue
          :selected="sort"
          :showSortBy="true"
          :options="sortOptions"
          @onChange="onChangeSelection"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ResetAllVue from '@/components/Common/ResetAll/ResetAll.vue'
import SearchReviewVue from '@/components/Common/SearchReview/SearchReview.vue'
import GlobalSortVue from '../GlobalSort/GlobalSort.vue'
import MultiSelectorVue from '../Dropdowns/MultiSelector.vue'

/**
 * This component renders the first row of filters on the **Comments** page
 */
export default {
  name: 'HorizontalLocalFilters',
  components: {
    ResetAllVue,
    SearchReviewVue,
    GlobalSortVue,
    MultiSelectorVue
  },
  computed: {
    ...mapState('filters', [
      'features',
      'selectedFeatures',
      'selectedCategories',
      'selectedRating',
      'subCategories',
      'companyList',
      'userType',
      'ratingList',
      'reactionList',
      'selectedProducts',
      'selectedIndustries',
      'selectedSourceList',
      'selectedCompanies',
      'selectedCompaniesVoe',
      'selectedFeatures',
      'selectedCountries',
      'selectedEmployeeStatuses',
      'selectedBusinessFunc',
      'selectedSeniorityLevel'
    ]),
    ...mapState('trendingbuzz', [
      'totalCount',
      'ratingData',
      'sort',
      'sortOptions'
    ]),
    ...mapState('common', ['currentModule']),
    ...mapState('user', ['userDetails']),
    noData () {
      if (this.currentModule === 'voc' && !(this.selectedProducts.length && this.selectedSourceList.length && this.selectedIndustries.length && this.selectedCompanies.length && this.userType.length && this.selectedFeatures.length && this.selectedRating.length && this.selectedCountries.length)) {
        return true
      } else if (this.currentModule === 'voe' && !(this.selectedCompaniesVoe.length && this.selectedEmployeeStatuses.length && this.selectedBusinessFunc.length && this.selectedSeniorityLevel.length && this.selectedFeatures.length && this.userType.length)) {
        return true
      }
      return false
    }
  },
  watch: {
    subCategories() {
      if (this.subCategories.length) {
        this.getLocalData()
      }
    },
    companyList() {
      if (this.companyList.length) {
        this.getLocalData()
      }
    },
    userType() {
      if (!this.userType.length) {
        this.resetRatingData()
      }
    }
  },
  created() {
    this.initializeSortOptions(this.currentModule)
  },
  mounted() {
    if (this.subCategories.length || this.companyList.length) {
      this.getLocalData()
    }
  },
  methods: {
    ...mapActions('filters', [
      'getAllLocalFiltersData',
      'getAllVoeLocalFiltersData',
      'updateSelectedFeatures',
      'updateSelectedRating',
      'updateUserType',
      'updateSearchKeyWord'
    ]),
    ...mapActions('analytics', ['updateEvent']),
    ...mapActions('trendingbuzz', [
      'resetRatingData',
      'updateSort',
      'initializeSortOptions'
    ]),
    /**
     * This method filters the comments according to user reactions
     * @param data {Array} - Takes in an array of objects. An object can look like the following:
     * <br>
     * *{ id: 'POSITIVE', name: 'Like' }*
     * @public
     */
    onChangeReactions(data) {
      this.updateUserType(data)
    },
    /**
     * This method filters the comments according to topics/features
     * @param data {Array} - Takes in an array of objects. An object can look like the following: <br>
     * *{ id: 3, is_standard: true, name: 'Customization', parent_id: null }*
     * @public
     */
    onChangeFeatures(data) {
      this.updateSelectedFeatures(data)
    },
    /**
     * This method filters the comments according to the rating of the review. Currently not in use
     */
    onChangeRating(data) {
      this.updateSelectedRating(data)
    },
    /** This method gets all the reviews that fall under the domain of the local filters
     * @public
     */
    getLocalData() {
      if (this.currentModule === 'voc') {
        this.getAllLocalFiltersData({
          categoryId: this.subCategories,
          subscriptionId: this.selectedCategories.subscriptionId,
          module: this.currentModule
        })
      } else if (this.currentModule === 'voe') {
        this.getAllVoeLocalFiltersData({ module: this.currentModule })
      }
    },
    /** This method resets all local filters to their default states. It results in all reviews under the domain of the global filters being displayed
     * @public
     */
    onReset() {
      this.updateUserType(this.reactionList)
      this.updateSelectedFeatures(this.features)
      this.updateSelectedRating(this.ratingList)
      this.updateSearchKeyWord('')
      this.updateSort({
        type: 'published_at',
        orderBy: null
      })
    },
    /**
     * This method is used to update the sorting for the drivers. It is only used in the table views
     * @param data {Object} - eg. *{ type: 'product_name', orderBy: 'asc' }*
     * @public
     */
    onChangeSelection(data) {
      this.updateSort(data)
    }
  }
}
</script>

<style lang="scss" scoped>
.year-fliter {
  display: flex;
  align-items: center;
  padding: 0px 0px 20px 0px;
  justify-content: space-between;
  .review-count {
    text-align: left;
    font: normal normal 600 16px/19px Quicksand;
    letter-spacing: 0px;
    color: #f57777;
    opacity: 1;
    width: 20%;
    @media (min-width: 1450px) and (max-width: 1700px) {
      font: normal normal 18px/23px Quicksand;
      font-weight: 600;
    }
    @media (min-width: 1700px) {
      font: normal normal bold 22px/28px Quicksand;
    }
  }
  .inside-flex {
    width: 40%;
    display: flex;
    flex-direction: row;
    padding-bottom: 0px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }
  .search-review {
    width: 57%;
    .search-container {
      width: 250px;
      @media (min-width: 1450px) and (max-width: 1700px) {
        width: 300px;
      }
      @media (min-width: 1700px) {
        width: 380px;
      }
      @media (min-width: 1850px) {
        width: 440px;
      }
    }
  }
  .golbal-sort {
    width: 33%;
  }
  .disabledtime {
    color: lightgrey;
  }
}
</style>
