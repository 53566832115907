<template>
  <b-button class="reset-button" @click="onClick"><p :class="[from]">Reset</p></b-button>
</template>

<script>
/** This component renders the *Reset* button in the global filters section. */
export default {
  name: 'ResetAll',
  props: ['from'],
  methods: {
    /** This method emits the *onReset* event */
    onClick () {
      this.$emit('onReset')
    }
  }
}
</script>

<style lang="scss" scoped>
.reset-button {
  outline: none;
  border: none;
  background: transparent;
  height: 100%;
  padding: 0px;
  margin-left: 10px;
  p {
    font-size: 12px;
    margin: 0px;
  }
  .trendingBuzz {
    color: #2780eb;
    text-decoration-line: underline;
  }
  .sidePanel {
    color: var(--inava-pink);
  }
}
</style>
